<template>
  <div>
    <h5>
      <strong>{{ $t("booking.board-basis") }} : </strong>
      {{ " " + basisCode + "  " + description }}
    </h5>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    room: Object,
    default: null,
  },
  data() {
    return {
      basisCode: '',
      description: '',
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
  },
  watch: {
    lang: 'updateLabelWithLang',
  },
  created() {
    this.updateLabelWithLang();
  },
  methods: {
    updateLabelWithLang() {
      const { translations } = this.room;
      // eslint-disable-next-line no-mixed-operators
      const roomBasis = translations?.basisName?.[this.room.roomBasisCode.value]?.[this.lang] || this.room?.roomBasis;
      const roomClassText = translations?.Room_Class?.[this.room.roomClass.value]?.[this.lang] || this.room?.roomClass.text;
      const roomTypeText = translations?.Room_Type?.[this.room.roomType.value]?.[this.lang] || this.room?.roomType?.value;

      this.basisCode = roomBasis;
      this.description = `${roomClassText} ${roomTypeText}`;
    },
  },
};
</script>

<style scoped>
@media (max-width: 479px) {
  .hotel_details_contain_box h5 {
    font-size: 15px;
  }
}
</style>
